<template>
  <div>
    <ReportModal 
      :data="reportData" 
      v-model="isOpenModal"/>
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <vs-table
        ref="table"
        v-model="selected"
        :max-items="itemsPerPage"
        :data="items"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-end">
          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="project_title">Email ผู้รับเรื่อง</vs-th>
          <vs-th sort-key="title">Email ผู้แจ้ง</vs-th>
          <vs-th sort-key="start_date">เบอร์โทรศัพท์ผู้แจ้ง</vs-th>
          <vs-th sort-key="is_approved">ข้อความ</vs-th>
          <vs-th sort-key="updated_at">วันที่สร้างข้อมูล</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <vs-td width="5%">{{ tr.email_to }}</vs-td>
              <vs-td width="5%">{{ tr.email_from }}</vs-td>
              <vs-td width="10%">{{ tr.telephone }}</vs-td>
              <vs-td class="message">
                <span>{{ tr.message }}</span>
              </vs-td>
              <vs-td width="15%"
              >{{ updatedAtDatetimeFormat(tr.created_at) }}
              </vs-td>
              <vs-td width="5%" class="whitespace-no-wrap">
                <div class="flex space-x-2">
                  <feather-icon
                    icon="SearchIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="openModal(tr.uuid)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'

import ReportModal from './ReportModal'

export default {
  name: 'Index',
  components: { ReportModal, LhDropdownPerPage },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'report')
    const { updatedAtDatetimeFormat } = useDatepicker()

    crudFunction.routePrefix.value = 'report'

    const items = computed(() => {
      return crudFunction.result.value
    })

    const isOpenModal = ref(false)
    const uuid = ref('')

    const openModal = (reportUuid) => {
      isOpenModal.value = true
      uuid.value = reportUuid
    }

    const reportData = computed(() => {
      const reportUuid = uuid.value
      if (reportUuid) {
        const report = items.value.find((data) => data.uuid === reportUuid)
        // project.location_flm = !isEmpty(project.location_flm) ? project.location_flm.split(',') : []
        return report
      }
      return {}
    })


    return {
      ...crudFunction,
      items,
      updatedAtDatetimeFormat,
      openModal,
      isOpenModal,
      uuid,
      reportData
    }
  },
}
</script>

<style lang="scss">
  .message {
    span {
      overflow: hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      max-width: 300px;
      display:inline-block;
    }
  }
</style>
